<template>
  <div class="container">
    <div class="search-box mb10">
      <van-search v-model="keyword" placeholder="搜索" />
    </div>
    <div class="main">
      <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
        <div class="contract-list">
          <div v-for="item in list" :key="item.contract_id" class="classes-list-item">
            <van-cell-group>
                <van-cell is-link :url="item.contract_pdf">
                    <template #title>
                        <span>{{item.contract_no}}</span>
                    </template>
                    <template #label>
                        <!-- <van-tag plain type="success">{{`${item.class_member_count}`}}人</van-tag> -->
                        <div class="custom-title">收费名称：{{`${item.fee_type_name}`}}</div>
                        <!-- <div class="custom-title">支付金额：{{`${item.total_fee}`}}</div> -->
                        <div class="custom-title">支付方式：{{`${item.trade_type_name}`}}</div>
                        <!-- <div class="custom-title">支付状态：{{`${item.trade_status}`}}</div> -->
                        <div class="custom-title">签约时间：{{`${item.contract_date}`}}</div>
                    </template>
                </van-cell>
            </van-cell-group>

            <!-- <div class="contract-list-item-hd">
              签约合同：{{ item.contract_no }}
            </div>
            <div class="contract-list-item-bd">
              <div class="contract-list-item-title">签约校区：{{ item.studio_name }}</div>
            </div>
            <div class="contract-list-item-ft">
              签约日期：{{ item.contract_date }}
            </div> -->

          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
import { Search ,Progress} from 'vant'
import PageMixin from '@/mixins/page'
import { debounce } from '@/common/util'

export default {
  name: 'StudentContractPdf',
  components: {
    [Search.name]: Search,
    [Progress.name]: Progress,
  },
  mixins: [PageMixin],
  data() {
    return {
      keyword: '',
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1
    }
  },
  watch: {
    keyword(val) {
      this.search()
    }
  },
  created() {
    // this.onListLoad()
  },
  methods: {
    onListLoad() {
      this.onPage()
    },
    search: debounce(function() {
      this.pageCurrent = 1
      this.onPage()
    }, 300),
    onPage() {
      this.$api.contract_index({ q: this.keyword, 'per-page': this.pageSize, page: this.pageCurrent,'pdf':1 }).then(res => {
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
        this.pageCurrent += 1
      })
    }
  }
}
</script>
<style lang="less" scoped>
    .classes-list{
        &-item{
            // width: 90%;
            // margin:0 auto;
            // background: #fff;
            // border-radius: 5px;
            margin-top: 10px;
            // padding: 10px 10px 0 10px;
            // box-shadow: 0 0 2px 3px hsla(0,0%,86.3%,.1);
            h1{
                font-size: 16px;
                
            }
        }
        &-btn{
            display: flex;
            align-items: center;
            justify-content: space-around;
            border-top: 1px solid #e8e8e8;
            margin-top: 10px;
            div{
                display: flex;
                align-items: center;
                padding: 10px;
                span{
                    margin-left: 5px;
                }
            }
        }
    }
</style>

<style lang="less">
    .classes-list-item{
        .van-cell-group{
            width: 95%;
            margin: 0 auto;
            border-radius: 10px;
            overflow: hidden;
            margin-top: 10px;
        }
        .van-cell__title{
            flex: 2;
        }
    }
</style>